import React from "react";
import moment from "moment";
import styled from "@emotion/styled";
import YouTube, { type YouTubeEvent, type YouTubePlayer } from "react-youtube";
import {
  Box,
  Button,
  CardContent,
  IconButton,
  LinearProgress,
  Modal,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Edit, Pause } from "@mui/icons-material";
import MaskedTextField from "@livepix/components/form/MaskedTextField";

const ModalCard = styled.div`
  max-width: 500px;
  margin: 50px auto 0;
  padding: 10px;
  border-radius: 10px;
  background: white;
`;

const ModalTitle = styled(Typography)`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
`;

const ModalSubtitle = styled(Typography)`
  margin-bottom: 30px;
  font-size: 16px;
  color: #666;
`;

const VideoPlayerContainer = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  background: #000;

  div.yt-video-player {
    width: 100%;
    height: 100%;
  }
`;

const VideoSliderContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  padding: 0;

  button:hover svg {
    fill: white;
  }
`;

export type VideoPlayerProps = {
  videoId: string;
  duration?: number;
  maxRangeDuration?: number;
  onChangeTimeRange?: (start: number, end: number) => void;
  enableTimeRangeEditor?: boolean;
};

export default function VideoPlayer(props: VideoPlayerProps) {
  const [player, setPlayer] = React.useState<YouTubePlayer>();

  const [showTimeRangeModal, setShowTimeRangeModal] = React.useState<boolean>(false);
  const [modalStartTime, setModalStartTime] = React.useState<string>("");
  const [modalEndTime, setModalEndTime] = React.useState<string>("");

  const [playing, setPlaying] = React.useState(false);
  const [playTime, setPlayTime] = React.useState<number>(0);
  const [timeRange, setTimeRange] = React.useState<[number, number]>([
    0,
    Math.min(props.maxRangeDuration || Infinity, props.duration || Infinity),
  ]);

  const play = () => {
    player?.playVideo();
  };

  const pause = () => {
    player?.pauseVideo();

    // Reset play time to the start of the range
    player?.seekTo(timeRange[0], true);
  };

  const formatDuration = (duration: number) => {
    let format = "mm:ss";

    if (props.duration && props.duration >= 3600) {
      format = "HH:mm:ss";
    }

    return moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format(format);
  };

  const parseTime = (time: string) => {
    const parts = time.split(":");

    let duration = 0;

    if (parts.length === 3) {
      duration += parseInt(parts[0]) * 3600;
      duration += parseInt(parts[1]) * 60;
      duration += parseInt(parts[2]);
    } else if (parts.length === 2) {
      duration += parseInt(parts[0]) * 60;
      duration += parseInt(parts[1]);
    } else {
      duration += parseInt(parts[0]);
    }

    return duration;
  };

  const handleTimeRangeChange = (value: number | number[], activeThumb: number) => {
    if (typeof value === "number") {
      return;
    }

    let start = value[0];
    let end = value[1];

    if (props.maxRangeDuration && end - start > props.maxRangeDuration) {
      if (activeThumb === 0) {
        end = start + props.maxRangeDuration;
      } else {
        start = end - props.maxRangeDuration;
      }
    }

    setTimeRange([start, end]);
    setModalStartTime(formatDuration(start));
    setModalEndTime(formatDuration(end));
  };

  React.useEffect(() => {
    if (props.onChangeTimeRange) {
      props.onChangeTimeRange(timeRange[0], timeRange[1]);
    }
  }, [timeRange]);

  React.useEffect(() => {
    if (!playing || !player) return;

    setPlayTime(player.getCurrentTime() as any);

    const interval = setInterval(() => {
      setPlayTime(player.getCurrentTime() as any);
    }, 500);

    return () => clearInterval(interval);
  }, [player, playing]);

  React.useEffect(() => {
    const regex =
      props.duration && props.duration > 3600 ? /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/ : /^([0-9]{2}):([0-9]{2})$/;
    const isValidStartTime = regex.test(modalStartTime);

    if (isValidStartTime) {
      handleTimeRangeChange([parseTime(modalStartTime), timeRange[1]], 0);
    }
  }, [modalStartTime]);

  React.useEffect(() => {
    const regex =
      props.duration && props.duration > 3600 ? /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/ : /^([0-9]{2}):([0-9]{2})$/;
    const isValidEndTime = regex.test(modalEndTime);

    if (isValidEndTime) {
      handleTimeRangeChange([timeRange[0], parseTime(modalEndTime)], 1);
    }
  }, [modalEndTime]);

  const [formattedStartTime, formattedEndTime] = React.useMemo(() => {
    return [formatDuration(timeRange[0]), formatDuration(timeRange[1])];
  }, [timeRange]);

  const inRangeTime = playTime - timeRange[0];
  const rangeDuration = timeRange[1] - timeRange[0];
  const progress = (inRangeTime / rangeDuration) * 100;

  return (
    <>
      {showTimeRangeModal && (
        <Modal open onClose={() => setShowTimeRangeModal(false)}>
          <ModalCard>
            <CardContent>
              <ModalTitle>Selecionar tempo</ModalTitle>
              <ModalSubtitle>Defina o tempo de início e fim do trecho que deseja exibir.</ModalSubtitle>
              <Box display="flex">
                <Box marginRight="5px">
                  <MaskedTextField
                    label="Início"
                    mask={props.duration && props.duration > 3600 ? "99:99:99" : "99:99"}
                    value={modalStartTime}
                    onAccept={(value) => setModalStartTime(value)}
                  />
                </Box>
                <Box marginLeft="5px">
                  <MaskedTextField
                    label="Fim"
                    mask={props.duration && props.duration > 3600 ? "99:99:99" : "99:99"}
                    value={modalEndTime}
                    onAccept={(value) => setModalEndTime(value)}
                  />
                </Box>
              </Box>
              <Box marginTop="20px" display="flex" justifyContent="flex-end">
                <Button variant="outlined" size="large" onClick={() => setShowTimeRangeModal(false)}>
                  Confirmar
                </Button>
              </Box>
            </CardContent>
          </ModalCard>
        </Modal>
      )}
      <VideoPlayerContainer>
        <YouTube
          videoId={props.videoId}
          className="yt-video-player"
          onReady={(e: YouTubeEvent) => setPlayer(e.target)}
          onPause={() => setPlaying(false)}
          onPlay={() => setPlaying(true)}
          opts={{
            width: "100%",
            height: "200px",
            playerVars: {
              autoplay: 0,
              controls: 0,
              disablekb: 1,
              fs: 0,
              rel: 0,
              start: timeRange[0],
              end: timeRange[1],
            },
          }}
        />
        {props.enableTimeRangeEditor && (
          <>
            <Box position="absolute" bottom="40px" width="100%">
              <LinearProgress value={progress} variant="determinate" />
            </Box>
            <VideoSliderContainer>
              <Box marginRight="10px">
                <IconButton onClick={playing ? pause : play}>
                  {playing ? <Pause htmlColor="#ccc" /> : <PlayArrowIcon htmlColor="#ccc" />}
                </IconButton>
              </Box>
              <Slider
                min={0}
                max={props.duration || 0}
                step={1}
                value={timeRange}
                onChange={(_, value, activeThumb) => handleTimeRangeChange(value, activeThumb)}
                valueLabelFormat={formatDuration}
                valueLabelDisplay="auto"
                color="secondary"
                disableSwap
              />
              <Box marginLeft="15px" marginRight="5px">
                <Typography color="#ccc" fontSize="16px" whiteSpace="nowrap">
                  {formattedStartTime}-{formattedEndTime}
                </Typography>
              </Box>
              <IconButton
                onClick={() => {
                  setShowTimeRangeModal(true);
                  setModalStartTime(formattedStartTime);
                  setModalEndTime(formattedEndTime);
                }}
              >
                <Edit htmlColor="#ccc" fontSize="small" />
              </IconButton>
            </VideoSliderContainer>
          </>
        )}
      </VideoPlayerContainer>
    </>
  );
}
