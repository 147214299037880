import React from "react";
import { Alert, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { AudioPlayer } from "./AudioPlayer";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import styled from "@emotion/styled";
import dynamic from "next/dynamic";

const MicrophoneWaveform = dynamic(() => import("./MicrophoneWaveform").then((mod) => mod.MicrophoneWaveform), {
  loading: () => <Typography>Carregando...</Typography>,
});

const AudioFieldContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 5px 12px;

  &:hover {
    border-color: #aaa;
  }
`;

const formatDuration = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substring(14, 19);
};

type Props = {
  color: string;
  maxDuration?: number;
  onRecorded: (audio: Blob) => void;
  onResize: () => void;
};

export default function AudioRecorder({ color, maxDuration, onRecorded, onResize }: Props) {
  const [recordDuration, setRecordDuration] = React.useState<number>(0);
  const [isAudioSilent, setAudioSilent] = React.useState<boolean>(false);

  const [recorder, setRecorder] = React.useState<MediaRecorder>();
  const [recording, setRecording] = React.useState<boolean>(false);

  const [audio, setAudio] = React.useState<Blob>();

  const [userMediaError, setUserMediaError] = React.useState<string>();

  const stopRecordingTimeout = React.useRef<NodeJS.Timeout>();

  const recordAudio = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setUserMediaError(undefined);
        setRecorder(new MediaRecorder(stream));
      })
      .catch((e) => {
        let message = "Erro ao acessar o microfone.";

        if (e instanceof DOMException && e.name === "NotAllowedError") {
          message = "O acesso ao microfone foi negado.";
        } else if (e instanceof DOMException && e.name === "NotFoundError") {
          message = "Nenhum microfone foi encontrado.";
        } else if (e instanceof DOMException && e.name === "NotReadableError") {
          message = "O microfone não está disponível.";
        } else if (e instanceof DOMException && e.name === "SecurityError") {
          message = "O acesso ao microfone foi bloqueado.";
        } else if (e instanceof DOMException && e.name === "AbortError") {
          message = "O acesso ao microfone foi cancelado.";
        } else if (e instanceof DOMException && e.name === "NotSupportedError") {
          message = "O microfone não é suportado.";
        } else if (e instanceof DOMException && e.name === "InvalidStateError") {
          message = "O microfone está em uso.";
        } else if (e instanceof DOMException && e.name === "OverconstrainedError") {
          message = "O microfone não suporta as configurações selecionadas.";
        } else if (e instanceof DOMException && e.name === "TypeError") {
          message = "O microfone não é suportado.";
        }

        setUserMediaError(message);
      });
  };

  const startRecording = (recorder: MediaRecorder) => {
    recorder.start();

    setRecording(true);
    setAudioSilent(false);

    stopRecordingTimeout.current = setTimeout(
      () => {
        stopRecordingTimeout.current = undefined;

        if (recorder.state === "recording") {
          stopRecording(recorder);
        }
      },
      (maxDuration || 30) * 1_000,
    );
  };

  const stopRecording = (recorder: MediaRecorder) => {
    setRecording(false);
    setRecordDuration(0);

    if (stopRecordingTimeout.current) {
      clearTimeout(stopRecordingTimeout.current);
    }

    recorder.stop();

    // Gather the audio data and create the blob
    recorder.ondataavailable = (e) => {
      setAudio(e.data);

      onRecorded(e.data);

      // Check if audio is silent
      const audioContext = new AudioContext();
      const reader = new FileReader();
      reader.readAsArrayBuffer(e.data);

      reader.onload = async (e) => {
        const buffer = await audioContext.decodeAudioData(e.target?.result as ArrayBuffer);
        const data = buffer.getChannelData(0);

        let isSilent = true;
        for (let i = 0; i < data.length; i++) {
          if (Math.abs(data[i]) > 0.02) {
            isSilent = false;
            break;
          }
        }

        setAudioSilent(isSilent);

        await audioContext.close();
      };
    };
  };

  React.useEffect(() => {
    if (!recording) return;

    setRecordDuration(0);

    const interval = setInterval(() => {
      setRecordDuration((current) => current + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [recording]);

  React.useEffect(() => {
    onResize();

    if (!recorder) {
      setRecordDuration(0);
      setAudio(undefined);
      setAudioSilent(false);
    }
  }, [recorder]);

  React.useEffect(() => recordAudio(), []);
  React.useEffect(() => onResize(), [isAudioSilent]);

  return (
    <>
      <AudioFieldContainer borderColor={userMediaError ? "#ad0000 !important" : undefined}>
        <Box flex="1">
          {Boolean(recorder) && (
            <>
              {Boolean(audio) ? (
                <AudioPlayer audio={audio!} />
              ) : (
                <Box display="flex">
                  <Box flex="1" marginRight="10px">
                    <MicrophoneWaveform color={color} />
                  </Box>
                  <Typography>
                    {formatDuration(recordDuration)}/{formatDuration(maxDuration || 30)}
                  </Typography>
                </Box>
              )}
            </>
          )}
          {Boolean(userMediaError) && (
            <Typography fontSize="16px" color="#ad0000">
              {userMediaError}
            </Typography>
          )}
          {Boolean(!userMediaError && !recorder) && (
            <Typography fontSize="16px" color="#666">
              Permita o acesso ao seu microfone.
            </Typography>
          )}
        </Box>
        <Box marginLeft="10px">
          {Boolean(!recording && !audio) && (
            <Tooltip title={userMediaError || "Iniciar gravação"}>
              <span>
                <IconButton color="primary" disabled={!recorder} onClick={() => startRecording(recorder!)}>
                  <MicIcon htmlColor={userMediaError ? "#ad0000" : undefined} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {Boolean(recording) && (
            <Tooltip title="Parar gravação">
              <IconButton color="primary" onClick={() => stopRecording(recorder!)}>
                <StopIcon />
              </IconButton>
            </Tooltip>
          )}
          {Boolean(!recording && audio) && (
            <Tooltip title="Gravar outro">
              <IconButton
                color="primary"
                onClick={() => {
                  setAudio(undefined);
                  setRecordDuration(0);
                  setAudioSilent(false);
                }}
              >
                <MicIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </AudioFieldContainer>
      {isAudioSilent && (
        <Box marginTop="-10px" marginBottom="20px">
          <Alert severity="warning">
            Não foi detectado som na gravação. Verifique seu microfone e grave novamente.
          </Alert>
        </Box>
      )}
    </>
  );
}
