import React from "react";
import styled from "@emotion/styled";
import { CircularProgress, IconButton, Slider, SliderProps } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Pause } from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-right: 6px;
`;

type Props = {
  audio: Blob;
};

export function AudioPlayer({ audio }: Props) {
  const [loading, setLoading] = React.useState(true);
  const [playing, setPlaying] = React.useState(false);
  const [position, setPosition] = React.useState(0);
  const [duration, setDuration] = React.useState(0);

  const [audioUrl, setAudioUrl] = React.useState<string>();
  const [audioElement, setAudioElement] = React.useState<HTMLAudioElement | null>(null);

  function changeCurrentTimeForTimeline(audioElement: HTMLAudioElement | null): SliderProps["onChange"] {
    return (e, v) => {
      if (audioElement && typeof v === "number") {
        audioElement.currentTime = v;
      }
    };
  }

  async function getAudioDuration(blob: Blob) {
    return new Promise<number>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target && event.target.result instanceof ArrayBuffer) {
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();

          try {
            const audioBuffer = await audioContext.decodeAudioData(event.target.result);
            resolve(audioBuffer.duration);
          } catch (error) {
            reject(error);
          }
        } else {
          reject(new Error("Invalid ArrayBuffer"));
        }
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  React.useEffect(() => {
    setAudioUrl(URL.createObjectURL(audio));

    getAudioDuration(audio).then((duration) => {
      setDuration(duration);
      setLoading(false);
    });
  }, [audio]);

  React.useEffect(() => {
    if (!audioElement) return;

    audioElement.addEventListener("playing", () => setPlaying(true));

    let updateTimeout: NodeJS.Timeout | null = null;

    audioElement.addEventListener("timeupdate", () => {
      if (updateTimeout) return;

      updateTimeout = setTimeout(() => {
        setPosition(audioElement.currentTime);
        updateTimeout = null;
      }, 100);
    });

    audioElement.addEventListener("pause", () => setPlaying(false));
    audioElement.addEventListener("ended", () => setPlaying(false));
    audioElement.addEventListener("error", (e) => {
      setPlaying(false);
      console.error(e);
    });
  }, [audioElement]);

  return (
    <Container>
      <audio src={audioUrl} style={{ display: "none" }} ref={setAudioElement} preload="auto" />
      <IconButton
        color="primary"
        style={{ marginRight: "20px" }}
        disabled={loading}
        onClick={() => {
          playing ? audioElement?.pause() : audioElement?.play();
        }}
      >
        {loading ? <CircularProgress size={30} /> : playing ? <Pause /> : <PlayArrowIcon />}
      </IconButton>
      <Slider
        size="small"
        min={0}
        step={0.001}
        max={duration || 0}
        onChange={changeCurrentTimeForTimeline(audioElement)}
        value={position || 0}
      />
    </Container>
  );
}
