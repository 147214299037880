import React from "react";
import Link from "next/link";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  TextField,
  Tooltip,
} from "@mui/material";
import { ReCAPTCHA } from "@livepix/components/common/Captcha";
import MoneyField from "@livepix/components/form/MoneyField";
import { User, UserCurrency } from "@livepix/sdk-js/types/core";
import useAlerts from "@livepix/components/hooks/useAlerts";
import { ProfileData, TipAction } from "@livepix/sdk-js/types/profile";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import AssistantIcon from "@mui/icons-material/Assistant";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MicIcon from "@mui/icons-material/Mic";
import Money from "helpers/Money";
import useWebservice from "@livepix/components/hooks/useWebservice";
import AudioRecorder from "./AudioRecorder";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import YouTube, { type YouTubeEvent, YouTubePlayer } from "react-youtube";
import VideoPlayer from "./VideoPlayer";

const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const MessageOptionsContainer = styled.div`
  width: 100%;
  display: flex;

  > button {
    flex: 1;
    font-size: 16px;
    background: #f4f4f4;
    margin: 0 5px 10px;

    &:hover {
      background: #e8e8e8;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 800px) {
      span.MuiButton-startIcon {
        display: none;
      }
    }
  }
`;

const AmountFieldContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

const CustomVoiceControl = styled.div`
  margin-bottom: 20px;
  margin-top: -10px;
  padding: 5px 15px;
  background: #f4f4f4;
  border-radius: 5px;

  span.MuiTypography-root {
    font-size: 16px;
  }
`;

const VideoMetadataContainer = styled.div`
  margin-top: -10px;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #f4f4f4;
  padding: 5px;

  div.MuiFormGroup-root {
    padding: 0 5px 5px;

    span.MuiTypography-root {
      font-size: 16px;
    }
  }
`;

const CurrencySelect = styled(Select)`
  width: 130px;
  height: 60px;
  margin-right: 10px;
`;

const Disclaimer = styled.p`
  margin-top: 10px;
  font-size: 12px;
  color: #666;
  text-align: center;

  a {
    color: #666;
    text-decoration: underline;
  }
`;

type Props = {
  color: string;
  profile?: ProfileData;
  username?: string;
  message?: string;
  currency?: string;
  messageEnabled?: boolean;
  minAmount: number;
  amount?: number;
  suggestedAmount?: number;
  customVoiceEnabled?: boolean;
  minCustomVoiceAmount?: number;
  maxCustomVoiceMessageLength?: number;
  audioEnabled?: boolean;
  minAudioAmount?: number;
  maxAudioDuration?: number;
  musicEnabled?: boolean;
  minMusicAmount?: number;
  maxMusicDuration?: number;
  currencies: UserCurrency[];
  maxMessageLength: number;
  products: ProfileData["products"];
  onTip: TipAction;
  user?: User;
  dummy?: boolean;
  captcha?: ReCAPTCHA;
  onResize: () => void;
};

type VideoMetadata = {
  id: string;
  platform: string;
  title: string;
  thumbnail: string;
  duration: number;
  statistics: {
    viewCount: number;
    likeCount: number;
    commentCount: number;
    favoriteCount: number;
  };
  restricted: boolean;
};

const toBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });

function YouTubeGetID(text: string): string | null {
  // Regular expression to match URLs in the text.
  const urlRegex = /https?:\/\/[^\s]+/g;
  const urls = text.match(urlRegex);
  if (!urls) {
    return null;
  }

  for (const urlString of urls) {
    try {
      const url = new URL(urlString);

      // Normalize hostname: remove "www." prefix if present.
      let hostname = url.hostname.toLowerCase();
      if (hostname.startsWith("www.")) {
        hostname = hostname.slice(4);
      }

      // Process only YouTube-related URLs.
      if (hostname === "youtube.com" || hostname === "youtu.be" || hostname.endsWith("youtube.com")) {
        // For shortened URLs like https://youtu.be/{videoId}
        if (hostname === "youtu.be") {
          const videoId = url.pathname.split("/")[1];
          if (videoId) {
            return videoId;
          }
        }

        // For URLs under youtube.com.
        if (hostname.endsWith("youtube.com")) {
          // Standard watch URL: https://youtube.com/watch?v={videoId}
          if (url.pathname === "/watch") {
            const videoId = url.searchParams.get("v");
            if (videoId) {
              return videoId;
            }
          }

          // Shorts URL: https://youtube.com/shorts/{videoId}
          if (url.pathname.startsWith("/shorts/")) {
            const parts = url.pathname.split("/");
            if (parts.length >= 3) {
              const videoId = parts[2];
              if (videoId) {
                return videoId;
              }
            }
          }

          // Embed URL: https://youtube.com/embed/{videoId}
          if (url.pathname.startsWith("/embed/")) {
            const parts = url.pathname.split("/");
            if (parts.length >= 3) {
              const videoId = parts[2];
              if (videoId) {
                return videoId;
              }
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  return null;
}

export default function TransactionForm({
  user,
  color,
  profile,
  currency,
  messageEnabled,
  minAmount,
  currencies,
  suggestedAmount,
  customVoiceEnabled,
  minCustomVoiceAmount,
  maxCustomVoiceMessageLength,
  maxMessageLength,
  audioEnabled,
  minAudioAmount,
  maxAudioDuration,
  musicEnabled,
  minMusicAmount,
  maxMusicDuration,
  products,
  dummy,
  captcha,
  onTip,
  onResize,
  ...props
}: Props) {
  const alerts = useAlerts();
  const webservice = useWebservice();

  const [username, setUsername] = React.useState<string>(props.username || user?.username || "");
  const [message, setMessage] = React.useState<string>(props.message || "");
  const [amount, setAmount] = React.useState<number>(props.amount || 0);
  const [audio, setAudio] = React.useState<Blob>();
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = React.useState<string>(currency || "BRL");

  const [useCustomVoice, setUseCustomVoice] = React.useState<boolean>(false);
  const [useAiMusic, setUseAiMusic] = React.useState<boolean>(false);
  const [useVideo, setUseVideo] = React.useState<boolean>(false);

  const [recordAudio, setRecordAudio] = React.useState<boolean>(false);

  const [generatingMessage, setGeneratingMessage] = React.useState<boolean>(false);
  const [loadingVideoMetadata, setLoadingVideoMetadata] = React.useState<boolean>(false);

  const [videoMetadata, setVideoMetadata] = React.useState<VideoMetadata>();
  const [params, setParams] = React.useState<Record<string, any>>({});

  const [usernameError, setUsernameError] = React.useState<string>();
  const [messageError, setMessageError] = React.useState<string>();
  const [amountError, setAmountError] = React.useState<string>();

  React.useEffect(() => {
    if (user?.username) setUsername(user.username);
  }, [user?.username]);

  React.useEffect(() => {
    if (suggestedAmount !== undefined) setAmount(suggestedAmount);
  }, [suggestedAmount]);

  React.useEffect(() => setAmount(suggestedAmount || 0), [selectedCurrencySymbol]);

  React.useEffect(() => onResize(), [usernameError, message, videoMetadata]);

  const enabledCurrencies = React.useMemo(() => currencies.filter((c) => c.enabled), [currencies]);

  const selectedCurrency = React.useMemo(
    () => enabledCurrencies.find((c) => c.currency.symbol === selectedCurrencySymbol),
    [enabledCurrencies, selectedCurrencySymbol],
  );

  const effectiveMinAmount = React.useMemo(() => {
    let effectiveMinAmount = minAmount;

    if (audioEnabled && audio) {
      effectiveMinAmount = minAudioAmount || minAmount;
    } else if (useCustomVoice) {
      effectiveMinAmount = minCustomVoiceAmount || minAmount;
    } else if (useAiMusic) {
      return minMusicAmount || minAmount;
    } else if (useVideo && params.start !== undefined && params.end !== undefined) {
      const duration = Math.round(params.end - params.start);
      const pricePerSecond = products.video.pricePerSecond || 0;

      effectiveMinAmount = Math.max(products.video.minimumAmount, duration * pricePerSecond);
    }

    return effectiveMinAmount;
  }, [
    audio,
    audioEnabled,
    minAmount,
    minAudioAmount,
    useCustomVoice,
    useAiMusic,
    useVideo,
    params,
    minCustomVoiceAmount,
  ]);

  const formattedMinimumAmount = React.useMemo(() => {
    return Money.format(effectiveMinAmount, selectedCurrencySymbol);
  }, [selectedCurrency, effectiveMinAmount]);

  const effectiveMaxLength = React.useMemo(() => {
    if (useCustomVoice && maxCustomVoiceMessageLength) {
      return maxCustomVoiceMessageLength;
    }

    if (useAiMusic) {
      return 200;
    }

    return maxMessageLength;
  }, [useCustomVoice, useAiMusic, maxCustomVoiceMessageLength, maxMessageLength]);

  const generateMessage = async () => {
    if (!captcha) {
      alerts.error("Não foi possível carregar o Captcha.");
      return;
    }

    setRecordAudio(false);
    setGeneratingMessage(true);

    try {
      const captchaToken = await captcha.executeAsync();

      const {
        data: { message },
      } = await webservice.post<{
        message: string;
      }>(
        `/profile/${profile?.username}/generate-message`,
        {
          captchaToken,
        },
        { timeout: 30_000 },
      );

      setMessage(message);
    } catch (e: any) {
      alerts.error(e.response?.data?.message || "Não foi possível gerar a mensagem.");
    } finally {
      captcha.reset();
      setGeneratingMessage(false);
    }
  };

  const loadVideoMetadata = async (platform: string, videoId: string) => {
    if (!captcha) {
      alerts.error("Não foi possível carregar o Captcha.");
      return;
    }

    setLoadingVideoMetadata(true);

    try {
      const captchaToken = await captcha.executeAsync();

      const { data } = await webservice.post<VideoMetadata>(
        `/profile/${profile?.username}/video-metadata`,
        {
          captchaToken,
          platform,
          video: videoId,
        },
        { timeout: 30_000 },
      );

      setVideoMetadata(data);
    } catch (e: any) {
      alerts.error(e.response?.data?.message || "Não foi possível carregar as informações do vídeo.");
    } finally {
      captcha.reset();
      setLoadingVideoMetadata(false);
    }
  };

  const submit = async () => {
    setUsernameError(undefined);
    setMessageError(undefined);
    setAmountError(undefined);

    if (!/^[A-zÀ-ú0-9 ]{0,20}$/.test(username)) {
      setUsernameError("O nome de usuário deve conter apenas letras, números e espaços e ter no máximo 20 caracteres.");
      return;
    }

    if (username.length === 1) {
      setUsernameError("O nome de usuário deve ter 2 ou mais caracteres.");
      return;
    }

    if (message.length > effectiveMaxLength) {
      setMessageError(`A mensagem deve ter até ${effectiveMaxLength} caracteres.`);
      return;
    }

    if (amount > 100_000) {
      setAmountError("Valor máximo atingido.");
      return;
    }

    if (amount < effectiveMinAmount) {
      setAmountError(`O valor mínimo é de ${formattedMinimumAmount}`);
      return;
    }

    let tipAudio: string | undefined;

    if (audioEnabled && audio) {
      tipAudio = await toBase64(audio);
    }

    onTip(selectedCurrencySymbol, amount, username, message, tipAudio, useCustomVoice, useAiMusic, useVideo, params)
      .then(() => {
        setAudio(undefined);
        setMessage("");
        setAmount(0);
      })
      .catch((e) => alerts.error(e.response?.data?.message || "Ocorreu um erro inesperado."));
  };

  React.useEffect(() => {
    if (!products.video?.enabled || !message) {
      if (videoMetadata) {
        setVideoMetadata(undefined);
        setUseVideo(false);
      }

      return;
    }

    const videoId = YouTubeGetID(message);
    if (!videoId) {
      return;
    }

    if (!videoMetadata || videoMetadata.id !== videoId) {
      setVideoMetadata(undefined);
      loadVideoMetadata("youtube", videoId).catch(console.error);
    }
  }, [products, videoMetadata, message]);

  React.useEffect(() => {
    if (recordAudio) {
      setUseCustomVoice(false);
      setUseAiMusic(false);
    } else if (!messageEnabled && customVoiceEnabled) {
      setUseCustomVoice(true);
    }

    onResize();
  }, [recordAudio]);

  React.useEffect(() => {
    if (useAiMusic) {
      setRecordAudio(false);
      setUseCustomVoice(false);
    } else if (!messageEnabled && customVoiceEnabled) {
      setUseCustomVoice(true);
    }

    onResize();
  }, [useAiMusic]);

  React.useEffect(() => {
    if (useVideo && videoMetadata) {
      setParams((current) => {
        return {
          ...current,
          platform: "youtube",
          videoId: videoMetadata.id,
          start: 0,
          end: Math.min(products.video.maximumDuration, videoMetadata.duration),
        };
      });
    } else {
      setParams((current) => {
        return {
          ...current,
          platform: undefined,
          videoId: undefined,
          start: undefined,
          end: undefined,
        };
      });
    }
  }, [useVideo, videoMetadata]);

  React.useEffect(() => {
    if (!messageEnabled && !customVoiceEnabled && audioEnabled) {
      setRecordAudio(true);
    } else if (!messageEnabled && customVoiceEnabled) {
      setUseCustomVoice(true);
    } else if (!messageEnabled && musicEnabled) {
      setUseAiMusic(true);
    }
  }, [messageEnabled, audioEnabled, customVoiceEnabled, musicEnabled]);

  React.useEffect(() => setUsernameError(undefined), [username]);
  React.useEffect(() => setMessageError(undefined), [message]);
  React.useEffect(() => setAmountError(undefined), [amount]);

  return (
    <>
      <FieldContainer>
        <TextField
          label="Seu nome de usuário"
          variant="outlined"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          inputProps={{ maxLength: 20 }}
          error={!!usernameError}
          helperText={usernameError}
          fullWidth
        />
      </FieldContainer>
      {effectiveMaxLength > 0 && (
        <>
          {Boolean(musicEnabled || audioEnabled) && (
            <MessageOptionsContainer>
              {audioEnabled && (
                <>
                  {Boolean(recordAudio) ? (
                    Boolean(profile?.products.message.enabled || profile?.products["ai-voice-message"].enabled) && (
                      <Button startIcon={<ModeEditOutlineIcon />} onClick={() => setRecordAudio(false)}>
                        Escrever
                      </Button>
                    )
                  ) : (
                    <Button startIcon={<MicIcon />} onClick={() => setRecordAudio(true)}>
                      Gravar áudio
                    </Button>
                  )}
                </>
              )}
              {musicEnabled && (
                <>
                  {Boolean(useAiMusic) ? (
                    Boolean(profile?.products.message.enabled || profile?.products["ai-voice-message"].enabled) && (
                      <Button startIcon={<ModeEditOutlineIcon />} onClick={() => setUseAiMusic(false)}>
                        Escrever
                      </Button>
                    )
                  ) : (
                    <Button startIcon={<MusicNoteIcon />} onClick={() => setUseAiMusic(true)}>
                      Música IA
                    </Button>
                  )}
                </>
              )}
            </MessageOptionsContainer>
          )}
          {Boolean(recordAudio) ? (
            <AudioRecorder color={color} maxDuration={maxAudioDuration} onRecorded={setAudio} onResize={onResize} />
          ) : (
            <FieldContainer>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>
                  {useAiMusic ? "Instruções" : products.video?.enabled ? "Mensagem ou link do vídeo" : "Mensagem"}
                </InputLabel>
                <OutlinedInput
                  name="message"
                  value={message}
                  label={useAiMusic ? "Instruções" : products.video?.enabled ? "Mensagem ou link do vídeo" : "Mensagem"}
                  placeholder={useAiMusic ? "Música sobre o sol no estilo funk." : undefined}
                  onChange={(e) => setMessage(e.target.value)}
                  inputProps={{
                    maxLength: effectiveMaxLength,
                  }}
                  disabled={loadingVideoMetadata}
                  error={!!messageError}
                  multiline
                  fullWidth
                  endAdornment={
                    !useAiMusic && (
                      <InputAdornment position="end">
                        <Tooltip title="Gerar mensagem com IA">
                          <IconButton size="small" onClick={() => generateMessage()} style={{ margin: "-6px 0" }}>
                            {generatingMessage || loadingVideoMetadata ? (
                              <CircularProgress size={30} />
                            ) : (
                              <AssistantIcon htmlColor={color} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }
                />
                <FormHelperText>{messageError || `${message.length}/${effectiveMaxLength} caracteres`}</FormHelperText>
              </FormControl>
            </FieldContainer>
          )}
        </>
      )}
      {Boolean(products.video?.enabled && videoMetadata) && (
        <>
          {videoMetadata!.statistics.viewCount < products.video.minimumViews ? (
            <Box margin="-10px 0 20px">
              <Alert severity="warning">
                O vídeo não atingiu o mínimo de visualizações ({products.video.minimumViews}) para ser exibido.
              </Alert>
            </Box>
          ) : videoMetadata!.restricted ? (
            <Box margin="-10px 0 20px">
              <Alert severity="warning">O vídeo está restrito a maiores de idade e não pode ser exibido.</Alert>
            </Box>
          ) : (
            <VideoMetadataContainer>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={useVideo} size="small" onChange={(_, checked) => setUseVideo(checked)} />}
                  label="Exibir vídeo na transmissão"
                />
              </FormGroup>
              <VideoPlayer
                videoId={videoMetadata!.id}
                duration={videoMetadata!.duration}
                enableTimeRangeEditor={useVideo}
                maxRangeDuration={products.video.maximumDuration}
                onChangeTimeRange={(start, end) => setParams({ ...params, start, end })}
              />
            </VideoMetadataContainer>
          )}
        </>
      )}
      {Boolean(messageEnabled && customVoiceEnabled && !recordAudio && !useAiMusic && !videoMetadata) && (
        <CustomVoiceControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={useCustomVoice} size="small" onChange={(_, checked) => setUseCustomVoice(checked)} />
              }
              label="Escolher voz gerada com IA"
            />
          </FormGroup>
        </CustomVoiceControl>
      )}
      <AmountFieldContainer>
        {enabledCurrencies.length > 0 && (
          <CurrencySelect
            value={selectedCurrencySymbol}
            disabled={currencies.length === 0}
            onChange={(e) => setSelectedCurrencySymbol(e.target.value as string)}
            fullWidth
          >
            <MenuItem value="BRL">
              <Box display="flex" alignItems="center">
                <img src={`/images/currencies/brl.png`} alt="BRL" width="20px" />
                <Box marginLeft="10px">R$</Box>
              </Box>
            </MenuItem>
            {enabledCurrencies.map((currency) => (
              <MenuItem key={currency.id} value={currency.currency.symbol}>
                <Box display="flex" alignItems="center">
                  <img
                    src={`/images/currencies/${currency.currency.slug}.png`}
                    alt={currency.currency.symbol}
                    width="20px"
                  />
                  <Box marginLeft="10px">{currency.currency.symbol}</Box>
                </Box>
              </MenuItem>
            ))}
          </CurrencySelect>
        )}
        <MoneyField
          label="Valor"
          value={amount}
          name="amount"
          currency={selectedCurrencySymbol}
          decimals={selectedCurrency?.currency.decimals}
          onChange={(value) => setAmount(value)}
          helperText={amountError || `O valor mínimo é de ${formattedMinimumAmount}`}
          error={!!amountError}
        />
      </AmountFieldContainer>
      <ProfileButton customcolor={color} onClick={() => submit()}>
        {useCustomVoice ? "Escolher Voz" : "Continuar"}
      </ProfileButton>
      <Disclaimer>
        Ao clicar em continuar, você declara que leu e concorda com os{" "}
        <Link href="/institucional/contrato-servico">Termos de Uso</Link> e a{" "}
        <Link href="/institucional/politica-privacidade">Política de Privacidade</Link>.
      </Disclaimer>
    </>
  );
}
